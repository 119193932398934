import React from 'react';
import * as styles from '../components/eleusis/eleusis.module.css';
import LoginPane from '../components/eleusis/login_pane.js';
import ActionBar from '../components/eleusis/action_bar.js';
import PlayerList from '../components/eleusis/player_list.js';

import { back as cardBack, fronts as cardFronts } from '../images/cards/bundle.js';

export default class Eleusis extends React.Component {
    constructor(props) {
        super(props);

        this.canvasRef = React.createRef();
        this.bottomContainerRef = React.createRef();
        this.resizeCanvas = this.resizeCanvas.bind(this);

        this.totalAssets = 0;
        this.loadedAssets = 0;

        this.state = {
            screen: 'loading',
            statusText: '',
            players: [],
            buttons: [],
            dealer: -1,
            turn: -1,

            loadedAssets: 0,
            totalAssets: 0,
        };
    }

    render() {

        return <div>
            {this.state.screen === 'loading' && <h1 class={styles.loadingText}>Loading Assets ({this.state.loadedAssets}/{this.state.totalAssets})</h1>}
            {this.state.screen === 'login' && <LoginPane onPlay={this.onPlay.bind(this)} />}
            {this.state.screen === 'connecting' && <h1 class={styles.connectingText}>Connecting...</h1>}
            {this.state.screen === 'ingame' && <div class={styles.bottomContainer} ref={this.bottomContainerRef}>
                <ActionBar buttons={this.state.buttons} statusText={this.state.statusText} />
                <PlayerList players={this.state.players} dealer={this.state.dealer} turn={this.state.turn} />
            </div>}
            <canvas class={styles.canvas} ref={this.canvasRef} />
        </div>;
    }

    componentDidMount() {
        this.resizeCanvas();
        window.addEventListener('resize', this.resizeCanvas);

        this.loadAssets();
    }

    resizeCanvas() {
        if (this.canvasRef.current) {
            this.canvasRef.current.width = window.innerWidth;
            this.canvasRef.current.height = window.innerHeight;
        }
    }

    loadAssets() {
        let imagesToLoad = [];
        let addImage = (image, src) => {
            imagesToLoad.push([image, src]);
            this.totalAssets++;
            this.setState({ totalAssets: this.totalAssets });
            image.onload = () => {
                this.loadedAssets++;
                if (this.loadedAssets < this.totalAssets) {
                    this.setState({ loadedAssets: this.loadedAssets });
                }
                else {
                    this.setState({ screen: 'login' });
                }
            }
        }

        this.cardBackImage = new Image();
        addImage(this.cardBackImage, cardBack);

        this.cardFrontImages = [];
        for (let cardFront of cardFronts) {
            let cardFrontImage = new Image();
            this.cardFrontImages.push(cardFrontImage);
            addImage(cardFrontImage, cardFront);
        }

        for (let imgArr of imagesToLoad) {
            imgArr[0].src = imgArr[1];
        }
    }

    onPlay(args) {
        if (!args.username || !args.room) {
            alert('Please specify both a username and a room');
            return;
        }

        let refs = {
            canvas: this.canvasRef,
            bottomContainer: this.bottomContainerRef,
        };
        this.game = createGame(this, refs, args);
    }
}



function createGame(pageComponent, refs, args) {
    let game = {};

    game.pageComponent = pageComponent;
    game.refs = refs;

    game.username = args.username;
    game.room = args.room.toUpperCase();

    // initialize ingame variables
    game.cards = [];
    game.players = [];
    game.dealer = -1;
    game.turn = -1;
    game.selectingCards = false;
    game.selectedCards = [];
    game.suggestedCards = [];
    game.playing = false;

    game.pan = [0, 0];

    // assets
    game.backImg = game.pageComponent.cardBackImage;
    game.frontImgs = game.pageComponent.cardFrontImages;

    // Loading screen shows while connecting to server
    game.pageComponent.setState({screen: 'connecting'});

    // Make socket
    game.socket = new WebSocket('wss://harryzhou.info:30000');

    createHelperFuncs(game);
    createNetworkHandler(game);
    createButtons(game);
    createClickHandler(game);
    createDragHandler(game);

    game.send = function(message) {
        game.socket.send(JSON.stringify(message));
    }

    window.requestAnimationFrame(() => draw(game));

    return game;
}


function createNetworkHandler(game) {
    game.networkHandler = {};

    // Send username and room upon connecting to server
    game.socket.addEventListener('open', function() {
        game.send({name: 'login', username: game.username, room: game.room});
    }, {once: true});

    // Return to login screen if socket closes
    game.ended = false;
    game.socket.addEventListener('close', function() {
        game.ended = true;
        game.pageComponent.setState({screen: 'login'});
    });

    // On any socket event, call a corresponding function from game.networkHandler
    game.socket.addEventListener('message', function(rawMessage) {
        let message = JSON.parse(rawMessage.data);
        if (typeof game.networkHandler[message.name] === 'function') {
            game.networkHandler[message.name](message);
        }
    });

    game.networkHandler.joined_room = function() {
        game.pageComponent.setState({screen: 'ingame'});
    };

    game.networkHandler.players = function(message) {
        game.players = message.players;
        game.updatePlayerList();
    };

    game.networkHandler.assign_id = function(message) {
        game.myself = game.getPlayer(message.id);
    };

    game.networkHandler.player_joined = function(message) {
        console.log(`player with id ${message.player.id} joined`);
        game.players.push(message.player);
        game.updatePlayerList();
    };

    game.networkHandler.player_left = function(message) {
        console.log(`player with id ${message.id} left`);
        game.players.splice(game.getPlayerIndex(message.id), 1);
        game.updatePlayerList();
    };

    game.networkHandler.cards = function(message) {
        game.cards = message.cards;
    };

    game.networkHandler.dealer = function(message) {
        game.dealer = message.id;
        game.updatePlayerList();
    }

    game.networkHandler.turn = function(message) {
        game.turn = message.id;
        game.suggestedCards = [];
        game.updatePlayerList();

        if (game.turn < 0) {
            return;
        }

        if (message.id === game.myself.id) {
            game.selectingCards = true;
            game.setButtons([game.buttons.noplay]);
        }
        else {
            game.selectingCards = false;
            game.setButtons([]);
        }

        if (game.myself.id === game.dealer) {
            game.setButtons([]);
        }

        game.setStatusText(`${game.getPlayer(game.turn).username}'s turn`);
    }

    game.networkHandler.game_end = function() {
        game.playing = false;
        game.turn = -1;
        game.dealer = -1;
        game.selectedCards = [];
        game.suggestedCards = [];
        game.setButtons([game.buttons.start]);
        game.setStatusText('Waiting for players to click start');
    };

    game.networkHandler.player_ready = function(message) {
        game.getPlayer(message.id).ready = true;
        game.updatePlayerList();
    }

    game.networkHandler.game_start = function() {
        game.playing = true;
        for (let p of game.players) {
            p.ready = false;
        }
        game.updatePlayerList();
        game.setButtons([]);
    }

    game.networkHandler.redeck = function(message) {
        let player = game.getPlayer(message.id);
        player.cards = message.cards;
        game.updatePlayerList();
    }

    game.networkHandler.deal = function(message) {
        let player = game.getPlayer(message.id);
        for (let card of message.cards) {
            player.cards.push(card);
        }
        player.cards.sort((a, b) => a - b);
        game.updatePlayerList();
    }

    game.networkHandler.suggest = function(message) {
        let player = game.getPlayer(message.id);
        game.suggestedCards = message.indices.map((idx) => player.cards[idx]);
        game.selectedCards = []; // clear selected cards (only changes anything if it's currently this client's turn)
        
        for (let idx of message.indices.sort().reverse()) {
            player.cards.splice(idx, 1);
        }

        if (game.dealer === game.myself.id) {
            game.setButtons([game.buttons.correct, game.buttons.incorrect]);
        }

        game.setStatusText(`${player.username} offered ${message.indices.length} cards. Dealer checking...`);
    }

    game.networkHandler.noplay = function(message) {
        let player = game.getPlayer(game.turn);

        if (game.dealer === game.myself.id) {
            game.myself.cards = player.cards;
            game.setButtons([game.buttons.correct, game.buttons.incorrect]);
        }

        game.setStatusText(`${player.username} no-play. Dealer checking...`);
    }

    game.networkHandler.response = function(message) {
        if (message.correct) {
            for (let card of game.suggestedCards) {
                game.cards.push([card]);
            }
        }
        else {
            game.cards[game.cards.length-1].push(game.suggestedCards);
        }
        
        game.suggestedCards = [];
    }

    game.networkHandler.points = function(message) {
        game.getPlayer(message.id).points = message.points;
        game.updatePlayerList();
    }
}

function createButtons(game) {
    game.buttons = {};

    game.buttons.recenter = {text: 'Re-center', callback: function() {
        game.pan = [0, 0];
    }};

    game.buttons.start = {text: 'Start', callback: function() {
        game.send({ name: 'ready' });
    }};

    game.buttons.noplay = {text: 'No play', callback: function() {
        game.send({ name: 'noplay' });
        game.setButtons([]);
    }};

    game.buttons.move = {text: 'Make move', callback: function() {
        game.send({ name: 'suggest', indices: game.selectedCards });
        game.selectingCards = false;
        game.setButtons([]);
    }};

    game.buttons.cancel = {text: 'Cancel', callback: function() {
        game.selectedCards = [];
        game.setButtons([game.buttons.noplay]);
    }};

    game.buttons.correct = {text: 'Correct', callback: function() {
        game.send({ name: 'response', correct: true });
        game.setButtons([]);
        game.myself.cards = [];
    }};

    game.buttons.incorrect = {text: 'Incorrect', callback: function() {
        game.send({ name: 'response', correct: false });
        game.setButtons([]);
        game.myself.cards = [];
    }};
}

function createClickHandler(game) {
    game.refs.canvas.current.onclick = function(event) {
        if (!game.playing) {
            return;
        }

        let canvas = game.refs.canvas.current;
        
        let clickX = event.pageX;
        let clickY = event.pageY;

        let cardScale = Math.min(canvas.width / game.backImg.width / 10, canvas.height / game.backImg.height / 5);
        let cardWidth = game.backImg.width * cardScale;
        let cardHeight = game.backImg.height * cardScale;
            
        let bottomHeight = 0;
        let bottomContainer = game.refs.bottomContainer.current;
        if (bottomContainer) bottomHeight = bottomContainer.offsetHeight;
        let handDisplayHeight = canvas.height - bottomHeight - cardHeight;

        if (game.myself && game.selectingCards && game.startDrag[1] > handDisplayHeight) {
            let cardInterval = cardWidth;
            if (cardWidth*game.myself.cards.length > canvas.width) {
                cardInterval = (canvas.width-cardWidth) / (game.myself.cards.length-1);
            }

            for (let i=game.myself.cards.length-1; i>=0; i--) {
                if (!game.selectedCards.includes(i) && clickY > handDisplayHeight && clickY < handDisplayHeight + cardHeight && clickX > i*cardInterval && clickX < i*cardInterval + cardWidth) {
                    game.selectCard(i);
                    return;
                }
            }
        }
    }

    game.selectCard = function(i) {
        if (game.selectedCards.length < 5) {
            if (game.selectedCards.length == 0) {
                game.setButtons([game.buttons.move, game.buttons.cancel]);
            }
            game.selectedCards.push(i);
        }
    }
}

function createDragHandler(game) {
    let canvas = game.refs.canvas.current;

    game.startDrag = [0, 0];

    canvas.onmousedown = function(event) {
        game.mouseIsDown = true;

        game.startDrag = [ event.offsetX, event.offsetY ];

        let cardScale = Math.min(canvas.width / game.backImg.width / 10, canvas.height / game.backImg.height / 5);
        let cardHeight = game.backImg.height * cardScale;
        let bottomHeight = 0;
        let bottomContainer = game.refs.bottomContainer.current;
        if (bottomContainer) bottomHeight = bottomContainer.offsetHeight;
        let handDisplayHeight = canvas.height - bottomHeight - cardHeight;
        if (event.offsetY < handDisplayHeight) game.startPan = game.pan;
    };

    canvas.onmouseup = function(event) {
        game.mouseIsDown = false;
        delete game.startPan;
    };

    canvas.onmousemove = function(event) {
        if (game.mouseIsDown && game.startPan) {
            game.pan = [
                game.startPan[0] + (game.startDrag[0] - event.offsetX),
                game.startPan[1] + (game.startDrag[1] - event.offsetY),
            ];
        }
    };
}

function createHelperFuncs(game) {
    game.updatePlayerList = function() {
        game.pageComponent.setState({players: game.players, dealer: game.dealer, turn: game.turn});
    };

    game.setButtons = function(buttons) {
        buttons = [game.buttons.recenter, ...buttons];
        game.pageComponent.setState({buttons: buttons});
    };

    game.setStatusText = function(text) {
        game.pageComponent.setState({statusText: text});
    };

    game.getPlayer = function(id) {
        for (let p of game.players) {
            if (p.id === id) {
                return p;
            }
        }
    };

    game.getPlayerIndex = function(id) {
        for (let i=0; i<game.players.length; i++) {
            if (game.players[i].id === id) {
                return i;
            }
        }
    };
}
    
function draw(game) {
    let canvas = game.refs.canvas.current;
    if (!canvas) {
        return;
    }
    let ctx = canvas.getContext('2d');
    // rect is cleared at start of draw, regardless of whether game is already ended.
    // This guarantees that when the game ends, the canvas is cleared.
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Stop animation loop on game end
    if (game.ended) {
        return;
    }
    else {
        window.requestAnimationFrame(() => draw(game));
    }

    let bottomHeight = 0;
    let bottomContainer = game.refs.bottomContainer.current;
    if (bottomContainer) bottomHeight = bottomContainer.offsetHeight;

    let cardScale = Math.min(canvas.width / game.backImg.width / 10, canvas.height / game.backImg.height / 5);
    let cardWidth = game.backImg.width * cardScale;
    let cardHeight = game.backImg.height * cardScale;
    let cardMargin = 20 * cardScale;

    ctx.lineWidth = 1;
    ctx.strokeStyle = 'black';
    function drawCard(id, x, y, pan) {
        if (id < 0 || id >= 52) return;
        let img = game.frontImgs[id];
        if (pan) {
            x -= game.pan[0];
            y -= game.pan[1];
        }
        ctx.drawImage(img, 0, 0, img.width, img.height, x+cardMargin, y+cardMargin, cardWidth-cardMargin*2, cardHeight-cardMargin*2);
        ctx.strokeRect(x + cardMargin, y + cardMargin, cardWidth-cardMargin*2, cardHeight-cardMargin*2);
    }

    // draw global card sequence
    let cardYBound = cardHeight;
    for (let i=0; i<game.cards.length; i++) {
        drawCard(game.cards[i][0], i*cardWidth, 0, true);
        let cardYPos = cardHeight;
        for (let j=1; j<game.cards[i].length; j++) {
            for (let k=0; k<game.cards[i][j].length; k++) {
                drawCard(game.cards[i][j][k], i*cardWidth + 100*k*cardScale, cardYPos + 200*k*cardScale, true);
            }
            cardYPos += cardHeight + 200*(game.cards[i][j].length-1)*cardScale;
        }
        if (cardYPos > cardYBound) {
            cardYBound = cardYPos;
        }
    }

    if (game.myself) {
        // draw cards in hand
        let cardInterval = cardWidth;
        if (cardWidth*game.myself.cards.length > canvas.width) {
            cardInterval = (canvas.width-cardWidth) / (game.myself.cards.length-1);
        }
        let handDisplayHeight = canvas.height - bottomHeight - cardHeight;
        ctx.fillStyle = 'beige';
        ctx.fillRect(0, handDisplayHeight, canvas.width, cardHeight);
        ctx.lineWidth = 1;
        ctx.strokeStyle = 'black';
        for (let i=0; i<game.myself.cards.length; i++) {
            if (!game.selectedCards.includes(i)) drawCard(game.myself.cards[i], i*cardInterval, handDisplayHeight, false);
        }

        // draw selected cards
        ctx.lineWidth = 2;
        ctx.strokeStyle = 'red';
        for (let i=0; i<game.selectedCards.length; i++) {
            let cardIdx = game.selectedCards[i];
            drawCard(game.myself.cards[cardIdx], game.cards.length*cardWidth + i*cardWidth, 0, true);
        }
    }

    // draw suggested cards
    ctx.lineWidth = 2;
    ctx.strokeStyle = 'blue';
    for (let i=0; i<game.suggestedCards.length; i++) {
        drawCard(game.suggestedCards[i], game.cards.length*cardWidth + i*cardWidth, 0, true);
    }
}