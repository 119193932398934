import React from 'react';
import * as styles from './player_list.module.css';

function Entry(props) {
    let { player, dealer, turn } = props;

    let nameDisplay = player.username;
    if (player.ready) nameDisplay += ' (ready)';
    if (player.id === dealer) nameDisplay += ' (dealer)';
    if (player.id === turn) nameDisplay += ' (turn)';

    return <div class={styles.entry}>
        <h1 class={styles.playerName}>{nameDisplay}</h1>
        <p class={styles.text}>{`${player.cards.length} cards`}</p>
        <p class={styles.text}>{`${player.points} points`}</p>
    </div>
}

export default class PlayerList extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { players, dealer, turn } = this.props;
        return <div class={styles.container}>
            {players.map((player) => <Entry player={player} dealer={dealer} turn={turn} key={player.id} />)}
        </div>
    }
}