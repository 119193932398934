import React from 'react';
import * as styles from './action_bar.module.css';

export default class ActionBar extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div class={styles.container}>
            {this.props.buttons.map( (button) => <button class={styles.button} onClick={button.callback} key={button.text}>{button.text}</button> )}
            <p class={styles.statusText}>{this.props.statusText}</p>
        </div>
    }
}