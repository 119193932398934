import React from 'react';
import * as styles from './login_pane.module.css';

export default class LoginPane extends React.Component {
    constructor(props) {
        super(props);
        this.usernameRef = React.createRef();
        this.roomRef = React.createRef();
    }

    render() {
        return <div class={styles.pane}>
            <h1>Eleusis</h1>
            <p>An inductive logic card game</p>
            <p><a href='https://en.wikipedia.org/wiki/Eleusis_(card_game)'>Wikipedia page</a></p>
            <input type="text" placeholder="username" class={styles.textbox} ref={this.usernameRef} />
            <input type="text" placeholder="room" class={styles.textbox} ref={this.roomRef} />
            <button onClick={() => {this.props.onPlay({
                username: this.usernameRef.current.value,
                room: this.roomRef.current.value,
            })}} class={styles.playButton}>Play</button>
        </div>
    }
}