import back from './back.png';

import c01 from './c01.png';
import d01 from './d01.png';
import h01 from './h01.png';
import s01 from './s01.png';
import c02 from './c02.png';
import d02 from './d02.png';
import h02 from './h02.png';
import s02 from './s02.png';
import c03 from './c03.png';
import d03 from './d03.png';
import h03 from './h03.png';
import s03 from './s03.png';
import c04 from './c04.png';
import d04 from './d04.png';
import h04 from './h04.png';
import s04 from './s04.png';
import c05 from './c05.png';
import d05 from './d05.png';
import h05 from './h05.png';
import s05 from './s05.png';
import c06 from './c06.png';
import d06 from './d06.png';
import h06 from './h06.png';
import s06 from './s06.png';
import c07 from './c07.png';
import d07 from './d07.png';
import h07 from './h07.png';
import s07 from './s07.png';
import c08 from './c08.png';
import d08 from './d08.png';
import h08 from './h08.png';
import s08 from './s08.png';
import c09 from './c09.png';
import d09 from './d09.png';
import h09 from './h09.png';
import s09 from './s09.png';
import c10 from './c10.png';
import d10 from './d10.png';
import h10 from './h10.png';
import s10 from './s10.png';
import c11 from './c11.png';
import d11 from './d11.png';
import h11 from './h11.png';
import s11 from './s11.png';
import c12 from './c12.png';
import d12 from './d12.png';
import h12 from './h12.png';
import s12 from './s12.png';
import c13 from './c13.png';
import d13 from './d13.png';
import h13 from './h13.png';
import s13 from './s13.png';

const fronts = [
    c01, d01, h01, s01,
    c02, d02, h02, s02,
    c03, d03, h03, s03,
    c04, d04, h04, s04,
    c05, d05, h05, s05,
    c06, d06, h06, s06,
    c07, d07, h07, s07,
    c08, d08, h08, s08,
    c09, d09, h09, s09,
    c10, d10, h10, s10,
    c11, d11, h11, s11,
    c12, d12, h12, s12,
    c13, d13, h13, s13
];

export {back, fronts};